import { createContext, useState } from "react";

export const SubscribeContext = createContext("");

export const SubscribeProvider = ({ children }) => {
	const [subscribe, setSubscribe] = useState(true);

	return (
		<SubscribeContext.Provider
			value={{
				subscribe,
				setSubscribe,
			}}>
			{children}
		</SubscribeContext.Provider>
	);
};
